<template>
  <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
    <div class="row m-0 p-3">
      <div class="col-12 col-xl-12 mx-0 mb-0 mb-xl-0">
        <p class="font-1-2s font-600">Product In Use</p>
      </div>
    </div>
    <div class="row mx-0 p-2 ">
      <div class="row col-12">
        <div class="col-12 col-xl-6 mt-2">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
        <div class="col-12 col-xl-6 ">
          <div class="input-group ml-3 mb-3 ">
            <div class="input-group-prepend">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(action)="row">
            <b-icon
              class="mr-3 text-main "
              type="button"
              font-scale="1.3"
              icon="eye-fill"
              aria-hidden="true"
              v-b-tooltip.hover="'View'"
              @click="view(row.item)"
            >
            </b-icon>
            <span
              v-html="$svg_icon.remove"
              type="button"
              class="mr-2 text-twd"
              v-if="$roleaction('Update Remarks')"
              v-b-tooltip.hover="'Delete'"
              @click="remove(row.item)"
            ></span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Manageremark",
  props: ["items", "itemlist", "totalRows"],
  data() {
    return {
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      mode: "skCode",
      select_mode: [
        { name: "ค้นหาด้วย skuCode", key: "skCode" },
        { name: "ค้นหา catCode", key: "catCode" },
        { name: "ค้นหา ชื่อสินค้า", key: "prNameTH" },
      ],
      fields: [
        {
          key: "No",
          label: "No.",
          class: "text-center",
          thStyle: { width: "4%" },
        },
        {
          key: "skCode",
          label: "skCode",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        { key: "catCode", label: "cat Code", class: "text-left" },
        {
          key: "prNameTH",
          label: "ชื่อสินค้า",
          class: "text-left",
          thStyle: { width: "45%" },
        },
        { key: "explain", label: "คำอธิบาย", class: "text-left" },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  mounted() {},
  methods: {
    view(e) {
      let routeData = this.$router.resolve({
        name: "view Product",
        params: { skcode: e.skCode },
      });
      window.open(routeData.href, "_blank");
    },
    async remove(e) {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `คุณต้องการยกเลิกหมายเหตุ ${e.explain} จาก${String(
            e.prNameTH
          ).toString(0, 50)} ใช่ หรือ ไม่ ?`
        );
        if (confirm) {
          let getAPI = await this.$serviceAPI.call_API(
            "get",
            `remark/updatepimproductbyremark/${e.skCode}`,
            {},
            1
          );
          this.$emit("getProductByRemark");
          if (getAPI.data == "OK") {
            this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          } else {
            this.$serviceMain.showErrorAlert(this, "บันทึกไม่สำเร็จ / Error");
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
  },
};
</script>

<style></style>
