<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">{{ title }}</p>
          </div>
          <div class="col-12 col-xl-6 text-right">
            <button class="btn bt-main mx-0 mb-3 mb-xl-0" @click="back()">
              <span v-html="$svg_icon.back"></span> Back
            </button>
          </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="row mx-0 my-5 p-0 ">
          <div class="col-12">
            <div class="row" v-if="data.showAll != '1'">
              <div class="col-12 col-lg-2 text-right mb-3">
                หมวดหมู่ <span class="text-danger"></span>
              </div>
              <div class="col-12 col-lg-9 mb-3">
                <div class="input-group input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="selectnow"
                    @click="CallModalfindbycatcode()"
                  />
                  <div class="input-group-append">
                    <button class="input-group-text" @click="Clearcatcode()">
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row  ">
              <div class="col-12 col-lg-2 text-right mb-3">
                คำอธิบาย <span class="text-danger">*</span>
              </div>
              <div class="col-12 col-lg-9 mb-3">
                <div class="input-group ">
                  <input
                    maxlength="200"
                    type="text"
                    class="form-control"
                    placeholder="คำอธิบาย"
                    v-model="data.explain"
                  />
                </div>
              </div>
            </div>
            <!-- --------------------- -->
            <div class="row  ">
              <div class="col-12 col-lg-2 text-right mb-3">
                Remark <span class="text-danger">*</span>
              </div>
              <div class="col-12 col-lg-10 mb-3">
                <div class="row">
                  <div class="col-6">
                    <p class="badge badge-primary">TH</p>
                    <tinymce
                      class="shadow bg-white rounded"
                      id="AddremarkModal"
                      :plugins="plugins"
                      :toolbar="toolbar"
                      :other_options="options"
                      v-model="data.remarkHtmlTH"
                    ></tinymce>
                  </div>
                  <div class="col-6">
                    <p class="badge badge-info ">EN</p>
                    <tinymce
                      class="shadow bg-white rounded"
                      id="AddremarkModalEN"
                      :plugins="plugins"
                      :toolbar="toolbar"
                      :other_options="options"
                      v-model="data.remarkHtmlEN"
                    ></tinymce>
                  </div>
                </div>
              </div>
            </div>

            <div class="row  px-5">
              <div class="col-12 px-5 mb-3">
                <div
                  class="form-check form-check-inline"
                  v-for="(e, i) in active"
                  :key="i"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :id="e.name"
                    :value="e.key"
                    v-model="data.active"
                  />
                  <label class="form-check-label" :for="e.name">{{
                    e.name
                  }}</label>
                </div>
              </div>
            </div>
            <!-- --------------------- -->
            <div class="row w-100">
              <div class="col-12 text-right">
                <button class="btn bt-main  px-5" @click="confirm()">
                  {{ textbt }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableProductByRemark
        v-if="$route.query.mode !== 'add'"
        :items="items"
        :itemlist="itemlist"
        :totalRows="totalRows"
        @getProductByRemark="getProductByRemark"
      />
    </div>
    <Modalfindbycatcode :key="reModalfindbycatcode" />
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
import Modalfindbycatcode from "../../components/Manage/Remarks/Modalfindbycatcode.vue";
import TableProductByRemark from "../../components/Manage/Remarks/TableProductByRemark.vue";
export default {
  components: { Modalfindbycatcode, tinymce, TableProductByRemark },
  name: "Manageremark",
  data() {
    return {
      reModalfindbycatcode: Date.now(),
      selectnow: this.$store.getters["categoryActions/get_category_remark"]
        .selectnow,
      catcode: this.$store.getters["categoryActions/get_category_remark"]
        .catcode,
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 150,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      // End Tinymce Editor
      title: "",
      textbt: "",
      data: {
        mode: "",
        catCode: "",
        explain: "",
        remarkHtmlTH: "",
        remarkHtmlEN: "",
        showAll: "",
        remarkType: "",
        active: 1,
      },
      active: [
        { key: 1, name: "Active" },
        { key: 0, name: "Inactive" },
      ],
      totalRows: 0,
      items: [],
      itemlist: [],
    };
  },
  watch: {
    getcategory: function(v) {
      this.selectnow = v.selectnow;
      this.lvl = v.lvl;
      this.catcode = v.catcode;
      this.checkcategory(this.catcode);
    },
  },
  computed: {
    getcategory: function() {
      return this.$store.getters["categoryActions/get_category_remark"];
    },
  },
  mounted() {
    this.getmode();
    this.Clearcatcode();
    this.getProductByRemark();
  },
  methods: {
    async getProductByRemark() {
      var data = { remarkId: this.$route.query.remarkId };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `remark/getproductbyremark`,
        data,
        1
      );
      this.items = getAPI.data;
      this.itemlist = getAPI.data;
      this.totalRows = getAPI.data.length;
    },
    async checkcategory(catCode) {
      let getAPI = await this.$serviceAPI.call_API(
        "get",
        `remark/checkcatbeforaddremark/${catCode}`,
        {},
        1
      );
      if (getAPI.data.length > 0) {
        const { catName, remarkHtmlTH } = getAPI.data[0];
        this.$serviceMain.showWarningAlert(
          this,
          `คุณมี Remark ในหมวดหมู่สินค้า ${catName} `,
          remarkHtmlTH
        );
      }
    },
    getmode() {
      let mode = this.$route.query.mode;
      if (mode == "add") {
        this.title = "Add Remarks";
        this.textbt = "ADD";
      } else if (mode == "edit") {
        this.title = "Edit Remarks";
        this.textbt = "UPDATE";
        this.getpimremark();
      }
    },
    async getpimremark() {
      let data = {
        find: "",
        page_size: 0,
        page_num: 100,
        mode: "",
        remarkId: this.$route.query.remarkId,
        catCode: "",
      };
      let getAPI = await this.$serviceAPI.call_API(
        "post",
        `remark/getpimremark`,
        data,
        1
      );

      let detdata = getAPI.data[0];
      this.selectnow = detdata.subDept;
      this.data = {
        catCode: detdata.catCode,
        explain: detdata.explain,
        remarkHtmlTH: detdata.remarkHtmlTH,
        remarkHtmlEN: detdata.remarkHtmlEN,
        showAll: detdata.showAll,
        remarkType: detdata.remarkType,
        active: detdata.active,
      };

      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async back() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.$router.push({ name: "Remarks" });
      }
    },
    validate() {
      if (
        this.data.explain !== "" &&
        this.data.remarkHtmlTH !== "" &&
        this.data.remarkHtmlEN !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    async confirm() {
      try {
        if (!this.validate()) {
          throw `กรุณาระบุข้อมูลให้ครบถ้วน / Please provide complete information`;
        }

        if (this.$route.query.mode == "add") {
          this.data.showAll = this.catcode == "" ? "3" : "2";
          this.data.catCode = this.catcode;
        }
        if (this.data.showAll !== "1") {
          this.data.catCode =
            this.catcode == "" ? this.data.catCode : this.catcode;
          this.data.showAll = this.data.catCode == "" ? "3" : "2";
        }

        let remarkId = this.$route.query.remarkId;
        let data = {
          id: remarkId == undefined ? "" : remarkId,
          datas: this.data,
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `remark/updatePimRemark`,
          data,
          1
        );

        if (getAPI.data[0].MSG == "OK") {
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        } else {
          throw getAPI.data[0].MSG;
        }

        this.redata();
        this.$router.push({ name: "Remarks" });
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async close() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.hide();
      }
    },
    redata() {
      this.data = {
        explain: "",
        remarkHtmlTH: "",
        remarkHtmlEN: "",
        showAll: 0,
        active: 1,
      };
    },
    hide() {
      this.$bvModal.hide("Modalfindbycatcoderemark");
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcoderemark");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.$store.dispatch("categoryActions/resetcategoryremark");
    },
  },
};
</script>

<style></style>
